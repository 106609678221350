import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { AiOutlineArrowLeft } from "react-icons/ai";
import styles from "./profile.module.scss";
import name from "./name.png";
import number from "./number.png";
import { admin } from "../localdata";

const EditProfile = () => {
  const fullname = admin?.fullName;
  const number = admin?.number;

  const [isOpen, setisOpen] = useState(false);
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/profile");
  };
 
  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        <div
          style={{
            height: "100vh",
            overflow: "scroll",
            width: "72vw",
            padding: "0 4vw 0 4vw",
            background: "#EFEFEF",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                width: "6vw",
                justifyContent: "space-between",
              }}
            >
              <AiOutlineArrowLeft
                onClick={goBack}
                style={{
                  alignSelf: "center",
                  cursor: "pointer",
                  color: "#1894B8",
                }}
              />
              <h3 style={{ color: "#1894B8" }}>Profile</h3>
            </div>
          </div>
        
          <div style={{ display: "flex", justifyContent: "space-between" }}>
         
            <div>
              <h3 style={{ marginLeft: "1.4vw" }}>Full Name</h3>
              <input
                defaultValue={fullname}
                className={styles.fullname}
                placeholder="Full Name"
              />
            </div>
            <div>
              <h3 style={{ marginLeft: "1.4vw" }}>Phone Number</h3>
              <input
                defaultValue={number}
                className={styles.fullname}
                placeholder="Number"
              />
            </div>
          </div>
          <div>
            <h3 style={{ marginLeft: "1.4vw" }}>Password</h3>
            <input
              
              className={styles.fullname}
              placeholder="Password"
            />
          </div>
          <div>
            <button type="submit"
              style={{
                border: "0",
                marginLeft: "62vw",
                marginTop: "32vh",
                color: "#FFFFFF",
                height: "4vh",
                width: "6vw",
                borderRadius: "6px",
                background: "#1894B8",
              }}
            >
              SAVE
            </button>
          </div>

        </div>
       
      </div>
  
    </div>
  );
};
export default EditProfile;
