import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { AiOutlineDown } from "react-icons/ai";
import Sidebar from "../Sidebar/Sidebar";
import Board from "../Board/Board";
import useSWR from "swr";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./dropdown.css";
import { colourStyles } from "./SelectStyle";
import Select from "react-select";
import axios, { all } from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { admin, baseUrl } from "../localdata";
import {
  BiDotsVerticalRounded,
  BiLeftArrowAlt,
  BiRightArrowAlt,
} from "react-icons/bi";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import stockIcon from "./stock.png";
import { Navigate, Link } from "react-router-dom";

const Stocks = () => {
  const open2 = true;

  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  console.log(adminId, "admin id here");
  const [data, setData] = useState([]);
  const fecthData = async () => {
    const { data } = await axios.get(`/user/get/stockStatement`);
    setData(data);
  };
  useEffect(() => {
    fecthData();
  }, []);

  // const fetcher = (url) => axios.get(url).then((res) => res.data);
  // const { data, error, loading } = useSWR(
  //   ` /admin/getAllStock/${adminId}`,
  //   fetcher,
  //   {
  //     refreshInterval: 1000,
  //   }
  // );
  // console.log(data, "all stock list data");

  const stockData = data?.stockData;
  console.log(stockData, "line 37");
  // get all stock name when select state->
  const [selectStockName, setSelectStockName] = useState("");
  const navigate = useNavigate();
  const [index, setIndex] = useState("");
  const [isOpen, setisOpen] = useState(false);
  const [isOpen1, setisOpen1] = useState(false);
  const [edit, setedit] = useState(false);
  const [stockName, setStockName] = useState("");

  console.log(stockName, "line 48");
  const splitStockName = stockName.split(",")[0];
  const splitStateName = stockName.split(",")[1];
  console.log(splitStockName, splitStateName);

  const [selectedStock, setSelectedStock] = useState(null);

  function handleMenuIconClick(id) {
    console.log(id, "get id on click");
    if (index == id) {
      setIndex(null);
    } else {
      setIndex(id);
    }
    // setIsOpen(!isOpen);
  }
  const toAdd = () => {
    navigate("/upload/stock");
  };

  const tob2b = () => {
    navigate("/b2b/upload");
  };

  const toStockfeat = () => {
    navigate("/stockfeato");
  };

  //  get filtered stock by name ->

  const filteredStockByname = data?.allStockStatements?.filter((s) => {
    return s.stockName === selectStockName;
  });
  console.log("filtered xl stock", filteredStockByname);
  // delete stock api->

  const handleClick = async (id) => {
    const stockId = id;
    try {
      const { data } = await axios.post(
        `/admin/deleteStock/${stockId}/${adminId}`
      );
      toast("Stock Deleted");
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  // impelement pagination featature->

  const [currentPage, setCurrentPage] = useState(1);

  const [stockPerPage] = useState(8);

  const indexOfLastStock = currentPage * stockPerPage;
  const indexOfFirstStock = indexOfLastStock - stockPerPage;

  // const currentStocks = data?.slice(indexOfFirstStock, indexOfLastStock);

  const next = () => {
    if (currentPage < Math.ceil(data?.length / stockPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // const allData = filteredStocks[0]?.stockData?.allData;
  if (!data) {
    return <span>Loading...</span>;
  }
  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />

        <div style={{ height: "100vh", width: "80vw", background: "#EFEFEF" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              paddingTop: "4vh",
              marginRight: "4.2vw",
            }}
          >
            {/* <button
              onClick={toStockfeat}
              style={{
                cursor: "pointer",
                width: "10vw",
                height: "4vh",
                borderRadius: "9px",
                marginRight: "2vw",
                background: "#1894B8",
                border: "none",
                color: "#FFFFFF",
              }}
            >
              Create stock
            </button> */}
            <AiOutlineArrowLeft onClick={prev} style={{ cursor: "pointer" }} />{" "}
            <AiOutlineArrowRight
              onClick={next}
              style={{ marginLeft: "1vw", cursor: "pointer" }}
            />
          </div>

          <button
            onClick={tob2b}
            style={{
              cursor: "pointer",
              height: "4vh",
              width: "6vw",
              border: "none",
              background: "#1894B8",
              color: "#FFFFFF",
              marginLeft: "70vw",
              marginTop: "4vh",
            }}
          >
            Upload
          </button>
          <div
            style={{
              display: "flex",
              height: "6vh",
              width: "80vw",
              background: "#1894B8",
              marginTop: "4vh",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "6vw",
              }}
            >
              <select
                style={{
                  background: "transparent",
                  color: "white",
                  border: "none",
                  outline: "none",
                }}
                value={selectStockName}
                onChange={(e) => setSelectStockName(e.target.value)}
              >
                <option style={{ color: "black" }}>Stock name</option>
                {data?.allStockStatements?.map((value, index) => {
                  return (
                    <>
                      <option style={{ color: "black" }}>
                        {value.stockName}
                      </option>
                    </>
                  );
                })}
              </select>
              {/* <div style={{ display: "flex", gap: "10px" }}>
                <label
                  style={{
                    color: "#FFFFFF",
                    border: "none",
                    marginLeft: "30vw",
                  }}
                >
                  Location
                </label>
              </div> */}
            </div>
          </div>
          <div
            className="container"
            style={{
              height: "60vh",
              width: "78vw",
              marginLeft: "1vw",
              overflow: "auto",
              zIndex: "0",
            }}
          >
            <div
              style={{
                marginLeft: "0vw",
                width: "15vw",
                alignItems: "center",
                justifyContent: "space-between",
                display: "grid",
              }}
            >
              {filteredStockByname &&
                filteredStockByname[0]?.excelData?.map((v, index) => {
                  return (
                    <ul style={{ display: "grid" }} key={index}>
                      <li>{v.value}</li>
                    </ul>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Stocks;
