import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Scroll from "react-scroll";
import axios from "axios";
import useSWR from "swr";
import useWindowScroll from "beautiful-react-hooks/useWindowScroll";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import { admin, baseUrl, states } from "../localdata";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";

const UpdateStock = () => {
  const [adminId, setId] = useState("");
  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  const [loading1, setLoading] = useState(false);
  const { id } = useParams();
  const stockId = id;
  const [data2, setData] = useState([]);
  const [data, setData2] = useState();

  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const open2 = true;

  const [state, setState] = useState("");

  const [stockName, setStockName] = useState("");
  const [basic, setBasicrate] = useState("");
  const [loading, setLoadingrate] = useState("");
  const [insurance, setInsurance] = useState("");
  const [gst, setGst] = useState("");
  const [tcs, setTcs] = useState("");
  const [thickness, setThickness] = useState("");
  const mydata = data?.stockData?.allData;
  const [businessType, setBusinessType] = useState("");
  const [sizes, setSizes] = useState([
    {
      size: "",
      amount: "",
    },
  ]);

  const scroll = Scroll.animateScroll;
  const btnRef = useRef(null);
  function handleScroll() {
    scroll.scrollTo(btnRef?.current?.offsetTop, {
      smooth: true,
    });
  }
  
  
  // const myArray =
  //   mydata &&
  //   Object.keys(mydata).map((key) => ({
  //     label: key,
  //     value: mydata[key],
  //   }));
  // const allData = myArray?.reduce(
  //   (obj, item) => Object.assign(obj, { [item.label]: item.value }),
  //   {}
  // );
  //! run this useEffect to autofill the form->
  useEffect(() => {
    setLoading(true);
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    const adminId = admin._id;
    if (adminId) {
      const fetchData = async () => {
        const { data } = await axios.get(
          ` /admin/singleStockData/${stockId}/${adminId}`
        );
        setData2(data);
        setLoading(false);
        setState(data?.stateName);
        setStockName(data?.stockName);
        setBasicrate(data?.basic);
        setLoadingrate(data?.loading);
        setTcs(data?.tcs);
        setBusinessType(data?.businessType);
        setInsurance(data?.insurance);
        setGst(data?.gst);
        setSizes(data?.sizes);
      };
      fetchData();
    }
  }, [stockId]);

  //  create input change for sizes->

  const handleFielInputChange = (event, index) => {
    let data = [...sizes];
    data[index][event.target.name] = event.target.value;
    setSizes(data);
  };

  function removeField(index) {
    const data = [...sizes];
    data.splice(index, 1);
    setSizes(data);
  }

  function addField() {
    let object = {
      size: "",
      amount: "",
    };
    setSizes([...sizes, object]);
  }

  const navigate = useNavigate();
  // const [data2, setData] = useState();
  const [fields, setFields] = useState(data?.stockData?.fields);
  const allData = data?.stockData?.allData;
  console.log(data);
  // const addField = () => {
  //   const newLabel = inputRef.current.value;
  //   setData((prevData) => [
  //     ...prevData,
  //     {
  //       label: newLabel,
  //       value: "",
  //     },
  //   ]);
  //   setFields((prevLabels) => [...prevLabels, newLabel]);
  //   inputRef.current.value = "";
  // };
  console.log(fields);
  const handleInputChange = (event, index) => {
    const newValue = event.target.value;
    setData((prevData) => {
      const newData = [...prevData];
      newData[index].value = newValue;
      return newData;
    });
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    } else {
      return;
    }
  };
  const date = new Date();
  const newDate = date.toISOString().substring(0, 10);
  const stockDate = newDate;

  const submitForm = async (formdata) => {
    try {
      const { data } = await axios.post(
        `/admin/editStock/${stockId}/${adminId}`,
        {
          state,
          stockName,
          businessType,
          stockDate,
          basic,
          loading,
          gst,
          tcs,
          insurance,
          sizes,
          businessType,
        }
      );
      console.log(data, "form data");
      toast("Stock Updated");
      console.log(data);
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />

        <form onSubmit={handleSubmit(submitForm)}>
          {loading1 ? (
            <Backdrop
              open={open2}
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <div
              style={{
                height: "100vh",
                overflow: "scroll",
                width: "74vw",
                paddingLeft: "6vw",
                background: "#EFEFEF",
              }}
            >
               <button
              
              style={{
                color: "#FFFFFF",
                background: "#1894B8",
                height: "4vh",
                width: "10vw",
                marginTop: "4vh",
                marginLeft: "53vw",
                border: "none",
                borderRadius: "9px",
              }}
              type="submit"
              disabled={isSubmitting}
            >
              Upload
            </button>
            {isSubmitting && (
              <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                Loading...
              </span>
            )}
              
              <div style={{ display: "flex", paddingTop: "6vh" }}>
                <div>
                  <h3>State</h3>

                  <select
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                    style={{ height: "4vh", width: "30vw" }}
                  >
                    <option selected value=""></option>
                    {states.map((state) => {
                      return (
                        <>
                          <option>{state}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Stock Name</h3>
                  <input
                    type="text"
                    required
                    value={stockName}
                    onChange={(e) => setStockName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="    Business"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <h3>Basic Rate</h3>
                  <input
                    type="number"
                    required
                    value={basic}
                    onChange={(e) => setBasicrate(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="    Basic Rate."
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Loading Rate</h3>
                  <input
                    required
                    type="number"
                    value={loading}
                    onChange={(e) => setLoadingrate(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="   Loading Rate"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <h3>Insurance</h3>
                  <input
                    type="number"
                    required
                    value={insurance}
                    onChange={(e) => setInsurance(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="    Insurance."
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>GST</h3>
                  <input
                    type="number"
                    required
                    defaultValue={data?.stockData?.gst}
                    value={gst}
                    onChange={(e) => setGst(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="   GST"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <h3>TCS</h3>
                  <input
                    type="number"
                    required
                    value={tcs}
                    onChange={(e) => setTcs(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="    TCS"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Business Type</h3>
                  <select
                    required
                    value={businessType}
                    onChange={(e) => setBusinessType(e.target.value)}
                    name=""
                    id=""
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  >
                    <option value="" selected></option>
                    <option>B2C</option>
                    <option>B2B</option>
                  </select>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid black",
                  width: "63vw",
                  marginTop: "2vh",
                  padding: "10px",
                }}
              >
                {sizes.map((value, index) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",

                          paddingBottom: "6vh",
                        }}
                      >
                        <div>
                          <h3>Size</h3>
                          <input
                            type="text"
                            required
                            name="size"
                            value={value.size}
                            onChange={(event) =>
                              handleFielInputChange(event, index)
                            }
                            placeholder="    Size."
                            style={{ height: "4vh", width: "30vw" }}
                          />
                        </div>
                        <div>
                          <h3 style={{ marginLeft: "2vw" }}>Amount</h3>
                          <input
                            type="text"
                            required
                            name="amount"
                            value={value.amount}
                            onChange={(event) =>
                              handleFielInputChange(event, index)
                            }
                            placeholder="   Amount"
                            style={{
                              height: "4vh",
                              width: "30vw",
                              marginLeft: "2vw",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "2vh",
                            }}
                          >
                            <button
                              type="button"
                              style={{
                                background: "crimson",
                                color: "white",
                                border: "none",
                                outline: "none",
                                cursor: "pointer",
                                padding: "6px",
                                borderRadius: "5px",
                              }}
                              onClick={() => removeField(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <button
                ref={btnRef}
                type="button"
                onClick={addField}
                style={{
                  color: "#FFFFFF",
                  background: "#1894B8",
                  height: "4vh",
                  width: "10vw",
                  marginTop: "2vmax",
                  marginLeft: "50vmax",
                  border: "none",
                  borderRadius: "9px",
                  cursor: "pointer",
                }}
              >
                Add Field
              </button>
              <div style={{ display: "block" }}>
                <div
                // style={{
                //   display: "flex",

                //   position: "relative",
                //   height: "20vh",
                //   width: "66vw",
                //   marginLeft: "-2vw",
                //   paddingLeft: "0vw",
                //   marginTop: "4vh",
                //   paddingBottom: "6vh",
                // }}
                >
                  {/* <div>
                  <h3 style={{ marginLeft: "2vw" }}>Field Name</h3>
                  <input
                    type="text"
                    ref={inputRef}
                    placeholder="    Enter Field Name"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div> */}
                  {/* a button is type submit by default always, to prevent this we can type=button */}
                  {/* <button
                  type="button"
                  onClick={addField}
                  style={{
                    color: "#FFFFFF",
                    background: "#1894B8",
                    height: "4vh",
                    width: "10vw",
                    marginTop: "1vh",
                    marginLeft: "23vw",
                    border: "none",
                    borderRadius: "9px",
                  }}
                >
                  Add Field
                </button> */}
                </div>

                {/* <button
                type="button"
                onClick={() => setisOpen(true)}
                style={{
                  color: "#FFFFFF",
                  background: "#1894B8",
                  height: "4vh",
                  width: "10vw",
                  marginTop: "1vh",
                  marginLeft: "54vw",
                  border: "none",
                  borderRadius: "9px",
                }}
              >
                Create Table
              </button> */}
              </div>

              <div
                style={{
                  position: "relative",
                  width: "66vw",
                  marginLeft: "0vw",
                  paddingLeft: "2vw",
                  marginTop: "4vh",
                  paddingBottom: "2vh",
                  marginBottom: "2vh",
                }}
              >
                {/* <div style={{ display: "flex", paddingTop: "6vh" }}>
              <div>
                <h3>State</h3>
                <input
                  type="text"
                  placeholder="    Choose Stock."
                  style={{ height: "4vh", width: "30vw" }}
                />
              </div>
              <div>
                <h3 style={{ marginLeft: "2vw" }}>Stock Name</h3>
                <input
                  type="text"
                  placeholder="    Business"
                  style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <h3>Basic Rate</h3>
                <input
                  type="text"
                  placeholder="    Basic Rate."
                  style={{ height: "4vh", width: "30vw" }}
                />
              </div>
              <div>
                <h3 style={{ marginLeft: "2vw" }}>Loading Rate</h3>
                <input
                  type="text"
                  placeholder="   Loading Rate"
                  style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <h3>Insurance</h3>
                <input
                  type="text"
                  placeholder="    Insurance."
                  style={{ height: "4vh", width: "30vw" }}
                />
              </div>
              <div>
                <h3 style={{ marginLeft: "2vw" }}>GST</h3>
                <input
                  type="text"
                  placeholder="   GST"
                  style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <h3>TCS</h3>
                <input
                  type="text"
                  placeholder="    TCS"
                  style={{ height: "4vh", width: "30vw" }}
                />
              </div>
              <div>
                <h3 style={{ marginLeft: "2vw" }}>Business Type</h3>
                <input
                  type="text"
                  placeholder="   Business Type"
                  style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                /> 
              </div>
            </div> */}
                {/* {myArray &&
                myArray.map((item, index) => {
                  return (
                    <div key={index}>
                      <h3>{item.label}</h3>
                      <input
                        type="text"
                        onKeyDown={handleKeyDown}
                        value={item.value}
                        placeholder="   "
                        onChange={(event) => handleInputChange(event, index)}
                        style={{ height: "4vh", width: "30vw" }}
                      />
                    </div>
                  );
                })} */}
              </div>
              <button
              
                style={{
                  color: "#FFFFFF",
                  background: "#1894B8",
                  height: "4vh",
                  width: "10vw",
                  marginTop: "4vh",
                  marginLeft: "53vw",
                  border: "none",
                  borderRadius: "9px",
                }}
                type="submit"
                disabled={isSubmitting}
              >
                Upload
              </button>
              {isSubmitting && (
                <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                  Loading...
                </span>
              )}
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                success
                theme="dark"
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
export default UpdateStock;
