import { useState } from "react";
import { BsEyeSlash } from "react-icons/bs";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';


const ChangePassword = () => {
    const navigate = useNavigate();
    const toLogin = () => {
        navigate("/")
    }
    const location = useLocation();
    const state = location.state;
    const userId = state?.id
    const [password, setPassword] = useState('')
    const [confirmP, setConfirmp] = useState('');
    const submitForm = async (e) => {
        if (password !== confirmP) {
            alert('password does not match')
        }
        e.preventDefault()
        try {
            const { data } = await axios.post("/admin/changePassword", {
                password,
                userId,
            }
            )
            console.log(data);
            alert('password changed')
        } catch (error) {
            alert(error)
        }
    }
    return (
        <>
            <form onSubmit={submitForm}>
                <div style={{ height: "100vh", display: "flex", width: "100vw" }}>
                    <div style={{ width: "40vw", height: "100vh", background: "#0F9EE8", boxShadow: "13px 0px 92px rgba(0, 0, 0, 0.18)" }}>

                        <img className='logo' /*src ={logo}*/ />
                        <div style={{ width: "35vw", marginTop: "32vh", marginLeft: "5vw" }}>
                            <h1 style={{ color: "white", fontSize: "60px", marginBottom: "1vh", }}>Change Password</h1>
                            <hr style={{ width: "19.5vw", height: "0.5vh", background: "#FFFFFF", marginLeft: "1vw" }} />
                        </div>



                    </div>
                    <div style={{
                        height: "7vh", marginLeft: "38vw", marginTop: "46.5vh", position: "absolute", width: "4vw", borderRadius: "30px",
                        background: "#0F9EE8"
                    }}>
                        <AiOutlineRight style={{ color: "#FFFFFF", marginTop: "1.8vh", marginLeft: "1.8vw", fontSize: "25px" }} />
                    </div>
                    <div>
                        <div style={{ marginTop: "33vh", width: "32vw", marginLeft: "15vw" }}>

                            <div style={{ marginTop: "4vh" }}>
                                <h3 style={{ color: "#0F9EE8", fontWeight: "500", marginBottom: "1vh" }}>New Password</h3>
                                <input required type="text" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='    Enter your phone number' style={{ paddingBottom: "0.8vh", width: "30vw", outline: "0", borderWidth: "0 0 0", boxShadow: " 0px 4px 25px rgba(0, 0, 0, 0.09)", borderRadius: "5px", height: "4vh" }} />
                            </div>
                            <div style={{ position: "relative", marginTop: "4vh" }}>
                                <h3 style={{ color: "#0F9EE8", fontWeight: "500", marginBottom: "1vh" }}>Confirm Password</h3>
                                <input required type="text" value={confirmP} onChange={(e) => setConfirmp(e.target.value)} placeholder='    Enter password' style={{ marginBottom: "-2vh", paddingBottom: "0.8vh", width: "30vw", outline: "0", borderWidth: "0 0 0", boxShadow: " 0px 4px 25px rgba(0, 0, 0, 0.09)", borderRadius: "5px", height: "4vh" }} />
                                <BsEyeSlash style={{ position: "absolute", marginTop: "1.5vh", marginLeft: "-2vw" }} />
                            </div>


                        </div>
                        <div>
                            <button type="submit" style={{ height: "5vh", cursor: "pointer", border: "0", color: "#FFFFFF", marginTop: "10vh", marginLeft: "22vw", width: "16vw", background: "#0F9EE8", borderRadius: "7px" }}>Change Password</button>
                        </div>




                    </div>
                </div>
            </form>
        </>
    )
}
export default ChangePassword;