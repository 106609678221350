import React from "react";
import useSWR from "swr";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const YearlyGraph = () => {
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(` /admin/getYearData`, fetcher, {
    refreshInterval: 1000,
  });
  
  const dataArray = Object.entries(data?data:{}).map(([month, orders]) => ({ month, orders }));
  const monthMapping = {
    janData: 'January',
    febData: 'February',
    marData: 'March',
    aprData: 'April',
    mayData: 'May',
    junData: 'June',
    julData: 'July',
    augData: 'August',
    sepData: 'September',
    octData: 'October',
    novData: 'November',
    decData: 'December',
  };
  
  const convertedDataArray = dataArray.map(({ month, orders }) => ({
    month: monthMapping[month],
    orders,
  }));
   
  return (
    <>
      <h1>Yearly graph</h1>
      <LineChart
        width={1100}
        height={550}
        data={convertedDataArray}
        margin={{ top: 60, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis  dataKey="month" />
        <YAxis  />
        <Tooltip
          formatter={(value, name) => [value, "orders"]}
          labelFormatter={(value) => `Month: ${value}`}
        />

        <Line
          type="monotone"
          dataKey="orders"
          stroke="#141414"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </>
  );
};

export default YearlyGraph;
