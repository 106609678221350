import { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import { useForm } from "react-hook-form";
import { admin, baseUrl } from "../localdata";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSWR from "swr";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const AddPay = () => {
  const [loadingName, setLoadingName] = useState(false);
  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });

  // fetch numbers list data->
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(`/admin/all/numbers`, fetcher, {
    refreshInterval: 1000,
  });
  console.log(data);
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const [paymentDate, setPaymentDate] = useState("");
  const [paymentTime, setPaymentTime] = useState("");
  const [fullName, setFullName] = useState("");
  const [number, setNumber] = useState("");
  const [productName, setProductName] = useState("");
  const [netPrice, setNetPrice] = useState("");
  const [orderQuantity, setOrderQuantity] = useState("");
  const [totalOutstandingPayment, setTotalOutstanding] = useState("");
  const [totalPaidAmount, setTotalPaidAMount] = useState("");
  const [orderNumber, setOrderNumber] = useState("");

  // handlechange for numbers->

  async function handleNumberChange(e) {
    setNumber(e.target.value);
    const number = e.target.value;
    setLoadingName(true);
    const { data } = await axios.get(`/admin/get/username/${number}`);
    setFullName(data?.name);
    console.log(data);
    setLoadingName(false);
  }

  const submitForm = async () => {
    try {
      const { data } = await axios.post(`/admin/addPayment/${adminId}`, {
        paymentDate,
        paymentTime,
        fullName,
        number,
        productName,
        netPrice,
        orderQuantity,
        totalOutstandingPayment,
        totalPaidAmount,
        orderNumber,
      });
      toast("Payment Added");
      console.log(data);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  const open2 = true;
  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        <form onSubmit={handleSubmit(submitForm)}>
          {loadingName && !data ? (
            <Backdrop
              open={open2}
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <div
              style={{
                height: "100vh",
                width: "74vw",
                paddingLeft: "6vw",
                background: "#EFEFEF",
              }}
            >
              <div style={{ display: "flex", paddingTop: "8vh" }}>
                <div>
                  <h3>Date</h3>
                  <input
                    required
                    value={paymentDate}
                    onChange={(e) => setPaymentDate(e.target.value)}
                    type="date"
                    placeholder="    Date"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Time</h3>
                  <input
                    required
                    value={paymentTime}
                    onChange={(e) => setPaymentTime(e.target.value)}
                    type="text"
                    placeholder="    Time"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div>
                  <h3>Full Name</h3>
                  <input
                    required
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    type="text"
                    placeholder="    Full Name"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Phone Number</h3>
                  {/* <input
                  required
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  type="text"
                  placeholder="    Phone Number"
                  style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                /> */}
                  <select
                    value={number}
                    onChange={handleNumberChange}
                    required
                    style={{ height: "5vh", width: "30vw", marginLeft: "2vw" }}
                  >
                    <option selected value=""></option>
                    {data?.allNumbers?.map((value) => {
                      return (
                        <>
                          <option>{value.number}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div>
                  <h3>Product Name</h3>
                  <input
                    required
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    type="text"
                    placeholder="    Product Name"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Net Price</h3>
                  <input
                    required
                    value={netPrice}
                    onChange={(e) => setNetPrice(e.target.value)}
                    type="text"
                    placeholder="    Net Price"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", marginTop: "4vh" }}>
                <div>
                  <h3>Order Quantity</h3>
                  <input
                    required
                    value={orderQuantity}
                    onChange={(e) => setOrderQuantity(e.target.value)}
                    type="number"
                    placeholder="    Order Quantity"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Order Number</h3>
                  <input
                    required
                    value={orderNumber}
                    onChange={(e) => setOrderNumber(e.target.value)}
                    type="number"
                    placeholder="    Total Outstanding Payment"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <h3>Total Outstanding Payment</h3>
                  <input
                    required
                    value={totalOutstandingPayment}
                    onChange={(e) => setTotalOutstanding(e.target.value)}
                    type="number"
                    placeholder="    Total Outstanding Payment"
                    style={{ height: "4vh", width: "30vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>Total Paid Amount</h3>
                  <input
                    required
                    value={totalPaidAmount}
                    onChange={(e) => setTotalPaidAMount(e.target.value)}
                    type="number"
                    placeholder="    Total Paid Amount"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>
              <button
                style={{
                  color: "#FFFFFF",
                  background: "#1894B8",
                  height: "4vh",
                  width: "10vw",
                  marginTop: "5vh",
                  marginLeft: "54vw",
                  border: "none",
                  borderRadius: "9px",
                }}
                disabled={isSubmitting}
              >
                ADD
              </button>
              {isSubmitting && (
                <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                  Loading...
                </span>
              )}
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                success
                theme="dark"
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
export default AddPay;
