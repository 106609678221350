import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useSWR from "swr";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { admin, baseUrl } from "../localdata";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { AiOutlineArrowLeft } from "react-icons/ai";
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import img1 from "./img1.png";
import img2 from "./img2.png";
import img3 from "./img3.png";
import img4 from "./img4.png";
import img5 from "./img5.png";

const Notifications = () => {
  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  console.log(adminId, "admin id here");

  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` /admin/notifications/${adminId}`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );
  console.log(data, "all notifications");

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/home");
  };
  const [isOpen, setisOpen] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const open2 = true;
  const handleClose = () => {
    setOpen(false);
  };

  const FormDialog = () => {
    return (
      <div>
        <Dialog
          sx={{ width: 1700 }}
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
        >
          <DialogContent>
            <DialogContentText>
              <div style={{ display: "flex" }}>
                <div>
                  <img src={img1} />
                </div>
                <div style={{ marginLeft: "1vw" }}>
                  <p style={{ marginTop: "0vh", marginBottom: "-2vh" }}>
                    Marie Balistreri
                  </p>
                  <p>Emmett84@gmail.com</p>
                </div>
              </div>
              Aliquet curabitur non posuere sed quam tristique. Pretium dui ut
              etiam aenean in. Orci enim duis neque mauris. Dignissim et
              habitasse fames consequat cum vestibulum ipsum nisl a. Feugiat in
              adipiscing et sagittis risus luctus semper. Enim donec vitae nec
              sem faucibus eget quis suspendisse luctus. Ullamcorper sem
              placerat ligula scelerisque molestie facilisi suspendisse in a.
              <br />
              Aliquet curabitur non posuere sed quam tristique. Pretium dui ut
              etiam aenean in. Orci enim duis neque mauris. Dignissim et
              habitasse fame
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  // const data = [
  //     {id:0 , photo:img1 , heading:"Vitae tincidunt risus elementum faucibus in egestas ipsum ut."      , content:"Diam in consectetur nulla non varius lectus nulla. Sem sed pulvinar nulla ac metus. Nullam velit fermentum nullam vel odio. Tristique lorem sodales id eu feugiat cursus eget. Pulvinar aenean quis morbi at tellus mauris porta bibendum."                                  },
  //     {id:1 , photo:img2 , heading:"Sollicitudin porttitor feugiat gravida augue non."                  , content:"Tincidunt sit congue platea faucibus vestibulum justo sed nec. Quam non at proin libero ultricies eu imperdiet. Diam quis nulla cursus at dictumst eu. Est netus orci est sit scelerisque. Quam nam id libero tristique sit amet ornare."                                    },
  //     {id:2 , photo:img3 , heading:"At semper tellus sit lectus id arcu et."                            , content:"At aliquam nec amet sit sed turpis risus. Ac purus accumsan duis volutpat commodo fermentum. Mi sollicitudin id odio gravida sed sed leo phasellus aliquet. Rhoncus tempor duis amet faucibus in eget nec tristique. Dignissim lacus tempor proin tristique molestie."       },
  //     {id:3 , photo:img4 , heading:"A dignissim at lacinia sollicitudin praesent maecenas in amet."     , content:"Ac pellentesque pellentesque sem odio amet augue. Enim sit scelerisque adipiscing enim amet aliquam nunc nisl morbi. Neque elementum enim facilisis amet cursus. Quam luctus amet dolor ullamcorper elit lorem ac. Imperdiet turpis aliquam massa felis euismod sit egestas."},
  //     {id:4 , photo:img5 , heading:"Viverra pellentesque at mus id at sollicitudin."                    , content:"Adipiscing duis sed ut libero fusce. Pellentesque diam orci sodales lectus nibh euismod sed nisi. Maecenas molestie velit senectus ac pellentesque vestibulum. Sed molestie mattis enim leo aliquam. Nisl duis a blandit tempus in sed consequat velit."                     },
  //     {id:5 , photo:img5 , heading:"Pellentesque non metus tellus condimentum congue vulputate cursus." , content:"Adipiscing duis sed ut libero fusce. Pellentesque diam orci sodales lectus nibh euismod sed nisi. Maecenas molestie velit senectus ac pellentesque vestibulum. Sed molestie mattis enim leo aliquam. Nisl duis a blandit tempus in sed consequat velit."                     }
  // ]
  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        {!data ? (
          <Backdrop
            open={open2}
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <div
            style={{
              height: "100vh",
              overflow: "scroll",
              width: "72vw",
              padding: "0 4vw 0 4vw",
              background: "#EFEFEF",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  width: "10vw",
                  justifyContent: "space-between",
                }}
              >
                <AiOutlineArrowLeft
                  onClick={handleNavigate}
                  style={{
                    alignSelf: "center",
                    cursor: "pointer",
                    color: "#1894B8",
                  }}
                />
                <h3 style={{ color: "#1894B8" }}>Notifications</h3>
              </div>
              <div style={{ overflow: "auto" }}>
                {data?.map((item, i) => {
                  return (
                    <div

                      key={i}
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        cursor: "pointer",
                        display: "flex",
                        height: "10vh",
                        marginTop: "3.5vh",
                        background: "#FFFFFF",
                        borderRadius: "4px"
                      }}
                    >
                      <div style={{ height: "90%", width: "6vw" }}>
                        {" "}
                        <img
                          style={{
                            height: "90%",
                            marginTop: "1vh",
                            marginLeft: "1vw",
                          }}
                          src={item.photo}
                        />
                      </div>
                      <div style={{ marginLeft: "2vw", marginTop: "-1vh" }}>
                        <div style={{ marginBottom: "-2vh" }}>
                          <h4>{item.msg}</h4>
                        </div>
                        <div>
                          <p style={{ fontSize: "10px" }}>{item.userData?.fullName}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <>
                  <FormDialog />
                </>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Notifications;
