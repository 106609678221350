import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import useSWR from "swr";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { admin, baseUrl } from "../localdata";
import Board from "../Board/Board";
import {
  BiDotsVerticalRounded,
  BiLeftArrowAlt,
  BiRightArrowAlt,
} from "react-icons/bi";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";

const UserManagement = ({ searchQuery, onSearch }) => {
  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  // console.log(adminId,'admin id here');
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` /admin/userManagement/${adminId}`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );

  

  const filtered = data?.filter((user) => {
    return user?.number?.trim().toLowerCase().includes(searchQuery);
  });

  const [newData, setNewData] = useState(data);

  useEffect(() => {
    if (searchQuery?.length < 2 || searchQuery == "") {
      setNewData(data);
    } else {
      setNewData(filtered);
    }
  }, [searchQuery, data]);

  const open2 = true;
  const navigate = useNavigate();

  const [edit, setedit] = useState(false);
  const [clickedUserId, setClickedUserId] = useState(null);
  const handleButtonClick = (userId) => {
    setClickedUserId(userId);
  };
  // const [index, setIndex] = useState(null);
  // function handleMenuIconClick(id) {
  //   console.log(id);
  //   if (index == id) {
  //     setIndex(null);

  //   } else {
  //     setIndex(id);

  //   }
  //   // setIsOpen(!isOpen);
  // }
  const toAdd = () => {
    navigate("/adduser");
  };
  const handleDelete = async (userId) => {
    try {
      const { data } = await axios.post(
        `/admin/delete/user/${adminId}`,
        { userId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      alert("User deleted");
    } catch (error) {
      console.log(error);
    }
  };
  // const reverseUsers = data?.reverse();
  // console.warn(reverseUsers);

  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar onSearch={onSearch} />
        {!data ? (
          <Backdrop
            open={open2}
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <div
            style={{ height: "100vh", width: "80vw", background: "#EFEFEF" }}
          >
            <center>
              <div
                style={{
                  display: "flex",
                  width: "78vw",
                  justifyContent: "end",
                  paddingTop: "4vh",
                }}
              >
                <button
                  onClick={toAdd}
                  style={{
                    cursor: "pointer",
                    background: "#1894B8",
                    height: "4vh",
                    border: "none",
                    width: "6vw",
                    borderRadius: "6px",
                  }}
                >
                  Add User
                </button>
                {/* <button style={{background:"#1894B8",height:"4vh",border:"none",width:"8vw",borderRadius:"6px",marginLeft:"2vw"}}>Detail Download</button> */}
              </div>
            </center>
            <div
              className="container"
              style={{
                height: "70vh",
                width: "78vw",
                marginLeft: "1vw",
                // overflow: "auto",
              }}
            >
              <h2>Registered User</h2>
              <hr style={{ width: "77vw" }} />
              <table className="tab" style={{ width: "100%" }}>
                <tr className="tabtr">
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Sr No.
                  </th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Full Name
                  </th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Mobile Number
                  </th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Zip Code
                  </th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Choose language
                  </th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Choose city
                  </th>
                  <th style={{ textAlign: "center", color: "#1894B8" }}>
                    Choose State
                  </th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Business Type
                  </th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Subscription
                  </th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Action
                  </th>
                </tr>

                {newData?.map((item, index) => (
                  <tr
                    key={index}
                    style={{ paddingTop: "2vh", position: "relative" }}
                  >
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {index}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.fullName}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.number}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.zipCode || "English"}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.language || "English"}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.city}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {item.state}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.businessType || "B2B"}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                     {item.subscribed === true
                        ? "Subscribed"
                        : "Not subscribed"}
                    </td>
                    <td
                      style={{
                        "font-size": "15px",
                        textAlign: "center",
                      }}
                    >
                      <a
                        onClick={() => handleButtonClick(item._id)}
                        style={{ cursor: "pointer" }}
                      >
                        <BiDotsVerticalRounded />
                      </a>
                    </td>
                    <div
                      style={{
                        background: "red",
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                        background: "#FFFFFF",
                        borderRadius: "10px",
                        display: clickedUserId === item._id ? "block" : "none",
                        position: "absolute",
                        right: "5vw",
                      }}
                    >
                      <>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/update/user/${item._id}`}
                        >
                          <div
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              padding: "4px",
                              borderBottom: "1px solid black",
                            }}
                          >
                            <MdModeEditOutline
                              style={{ paddingRight: "2vw" }}
                            />
                            <h5 style={{ margin: "0" }}>Edit</h5>{" "}
                          </div>
                        </Link>
                        <div
                          onClick={() => handleDelete(item._id)}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            padding: "4px",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <MdDelete style={{ paddingRight: "2vw" }} />
                          <h5 style={{ margin: "0" }}>Delete</h5>{" "}
                        </div>
                        {/* <div style={{cursor:"pointer",display:"flex",padding:"4px" }}><IoPrintSharp style={{paddingRight:"2vw"}}/><h5 style={{margin: "0" }}>Print</h5> </div> */}
                      </>
                    </div>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default UserManagement;
