import { useState, useEffect, memo } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import "./Home.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Board from "../Board/Board";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import useSWR from "swr";
import axios from "axios";
import { admin, baseUrl } from "../localdata";

const Home = ({ onSearch, searchQuery }) => {
  const [adminId, setId] = useState("");
  const open2 = true;
  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  }, []);

  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` /admin/dashboardUserData/${adminId}/?offset=10`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );
    
  const filtered =  data?.userList?.filter((user) => {
    return user?.number?.trim().toLowerCase().includes(searchQuery);
  });

  const [newData, setNewData] = useState(data?.userList);
console.log(newData)
  useEffect(() => {
    if (searchQuery?.length < 2 || searchQuery == "") {
      setNewData(data?.userList);
    } else {
      setNewData(filtered);
    }
  }, [searchQuery, data]);

  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar onSearch={onSearch} />
        <div style={{ height: "100vh", width: "80vw", background: "#EFEFEF" }}>
          <div style={{ display: "flex", marginLeft: "10vw" }}>
            <Board style={{}} />
          </div>
          <div
            className="container"
            style={{
              height: "70vh",
              width: "78vw",
              marginLeft: "1vw",
              overflow: "auto",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2>Registered User</h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "4vw",
                }}
              >
                <AiOutlineArrowLeft style={{ cursor: "pointer" }} />{" "}
                <AiOutlineArrowRight
                  style={{ marginLeft: "1vw", cursor: "pointer" }}
                />
              </div>
            </div>

            <hr style={{ width: "77vw" }} />
            <table className="tab" style={{ width: "100%" }}>
              <tr className="tabtr">
                <th style={{ textAlign: "start", color: "#1894B8" }}>Sr No.</th>
                <th style={{ textAlign: "start", color: "#1894B8" }}>
                  Full User Name
                </th>
                <th style={{ textAlign: "start", color: "#1894B8" }}>
                  Mobile Number
                </th>
                {/* <th style={{ textAlign: "start", color: "#1894B8" }}>
                  Password
                </th> */}
                <th style={{ textAlign: "start", color: "#1894B8" }}>City</th>
                <th style={{ textAlign: "start", color: "#1894B8" }}>State</th>
                <th style={{ textAlign: "center", color: "#1894B8" }}>
                  Business Type
                </th>
                <th style={{ textAlign: "start", color: "#1894B8" }}>
                  Subscription
                </th>
              </tr>
              {!data ? (
                <Backdrop
                  open={open2}
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : (
                newData?.map((item, i) => (
                  <tr key={i} style={{ paddingTop: "2vh" }}>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {i + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.fullName}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.number}
                    </td>
                    {/* <td
                        style={{
                          textAlign: "start",
                        }}
                      >
                        {123}
                      </td> */}
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.city}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.state}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {item.businessType}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {item.subscribed === true
                        ? "Subscribed"
                        : "Not subscribed"}
                    </td>
                    {/* <td style={{
                        
                        "font-size": "15px",
                        textAlign:"center"
                      }}><a onClick={()=> handleMenuIconClick((item.id))} style={{cursor:"pointer"}}><BiDotsVerticalRounded/></a></td> */}
                    {/* <div style={{  boxShadow:"0px 4px 20px rgba(0, 0, 0, 0.15)",background: "#FFFFFF",borderRadius:"10px" ,display: index === item.id ? "block" : "none" , position: "absolute" ,right:"1.5vw"}}> */}
                    {/* <>
                      <div onClick={toEdit} style={{ cursor:"pointer",display:"flex" ,padding:"4px" ,borderBottom:"1px solid black"}}><MdModeEditOutline style={{paddingRight:"2vw"}}/><h5 style={{margin: "0" }}>Edit</h5></div>
                      <div style={{ cursor:"pointer",display:"flex",padding:"4px",borderBottom:"1px solid black" }}><MdDelete style={{paddingRight:"2vw"}}/><h5 style={{margin: "0" }}>Block</h5> </div>
                      </> */}
                    {/* </div> */}
                  </tr>
                ))
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Home); // making it pure component.
