import React, { useState, useRef, useEffect } from "react";
import * as XLSX from "xlsx";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import upload from "./large.png";

const StockUpload = () => {
  const [formData, setFormData] = useState([]);
  const imageRef = useRef();
  const spanref = useRef();

  useEffect(() => {
    const element = imageRef.current;

    element.addEventListener("change", () => {
      const inputImage = element.files[0];
      spanref.current.innerHTML = inputImage.name;
    });
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const extractedData = XLSX.utils.sheet_to_json(worksheet);
      setFormData(extractedData);
    };
    reader.readAsBinaryString(file);
  };

  // create a function to generate input fields dynamically using key value pairs of object
  const generateInputFields = (object, index) => {
    const inputFields = [];
    // used for of loop to iterate over the properties of the object->
    for (const [key, value] of Object.entries(object)) {
      inputFields.push(
        <div style={{ display: "flex", gap: "20px" }} key={`${index}-${key}`}>
          <label htmlFor={`input-${index}-${key}`}>{key}:</label>
          <input
            id={`input-${index}-${key}`}
            name={`${key}[${index}]`}
            defaultValue={value}
          />
        </div>
      );
    }
    return inputFields;
  };

  console.log(formData, "line 19");

  const submitForm = async (e) => {
    e.preventDefault();
    try {
    } catch (error) {
      
    }
  };

  return (
    <>
      <div className="base">
        <div>
          <Sidebar />
        </div>
        <div>
          <Navbar />
          <form onSubmit={submitForm}>
            <div style={{ display: "flex", gap: "20px" }}>
              <label htmlFor="excel">
                <img
                  src={upload}
                  alt="upload"
                  style={{ width: "6vw", cursor: "pointer" }}
                />
              </label>
              <input
                ref={imageRef}
                style={{ display: "none" }}
                id="excel"
                type="file"
                onChange={handleFileUpload}
              />
              <span
                style={{ fontSize: "12px", marginTop: "5vh" }}
                ref={spanref}
              ></span>
              <div style={{ display: "grid" }}>
                {formData.map((data, index) =>
                  generateInputFields(data, index)
                )}
              </div>
              <button
                type="submit"
                style={{
                  cursor: "pointer",
                  width: "10vw",
                  height: "4vh",
                  borderRadius: "9px",
                  marginRight: "2vw",
                  background: "#1894B8",
                  border: "none",
                  color: "#FFFFFF",
                }}
              >
                Upload Stock
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default StockUpload;
