import { useState, useEffect, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import useSWR from "swr";
import axios from "axios";
import { admin, baseUrl } from "../localdata";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import upload from "./medium.png";

const EnquiryDetail = ({ id }) => {
  // const { id } = useParams();
 
  const [adminId, setId2] = useState("");
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId2(admin._id);
    }
  });
  const enquiryId = id;
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    `/admin/getSingleEnquiry/${enquiryId}/${adminId}`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );

  console.log(data, "enquiry detial here");

  console.log(id, "enquiry id here");

  const [enquiryMsg, setMessage] = useState("");
  const [enquiryImage, setImage] = useState("");

  const imageref = useRef(null);

  const spanref = useRef(null);

  useEffect(() => {
    const element = imageref.current;

    element.addEventListener("change", () => {
      const inputImage = element.files[0];
      spanref.current.innerHTML = inputImage.name;
    });
  });
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);

    console.warn(e.target.files[0]);
  };
  console.log(enquiryMsg);
  async function submitForm() {
    console.log("clicked");
    const myForm = new FormData();
    myForm.append("enquiryMsg", enquiryMsg);
    myForm.append("enquiryImage", enquiryImage);
    try {
      const { data } = await axios.post(
        `/admin/sendEnquiry/${adminId}/${id}`,

        myForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(data);
      toast("Enquiry Sent");
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  }

  return (
    <>
      <div className="base">
        {/* <div>
          <Sidebar />
        </div> */}
        <div>
          {/* <Navbar /> */}
          <h3 style={{ marginLeft: "2vw" }}>Enquiry</h3>
          <div style={{ display: "flex", marginLeft: "2vw" }}>
            <div>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "10px",
                  padding: "0 10px 10px 10px",
                  width: "40vw",
                }}
              >
                <p style={{ fontSize: "12px" }}>
                  {data?.user?.enquiry}
                  <br />

                  <br />
                </p>
                <div style={{ display: "flex" }}>
                  <img
                    src={data?.user?.images}
                    style={{
                      width: "15vw",
                      height: "15vh",
                      marginLeft: "1.5vw",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "8vh",
                  marginLeft: "28vw",
                }}
              >
                {/* <button
                  tabIndex="-1"
                  type="button"
                  style={{
                    height: "4vh",
                    width: "6vw",
                    color: "#1894B8",
                    background: "#FFFFFF",
                    borderRadius: "6px",
                    border: "1px solid #1894B8",
                    cursor: "pointer",
                    marginLeft: "6vw",
                  }}
                >
                  Cancel
                </button> */}
                {/* <button
                    tabIndex="-1"
                    type="button"
                    style={{
                      height: "4vh",
                      width: "6vw",
                      background: "#1894B8",
                      color: "#FFFFFF",
                      borderRadius: "6px",
                      border: "none",
                      marginLeft: "2vw",
                    }}
                  >
                    Reply
                  </button> */}
              </div>
            </div>
            <hr
              style={{
                marginLeft: "2vw",
                marginTop: "-4vh",
                height: "60vh",
              }}
            />

            <form onSubmit={handleSubmit(submitForm)}>
              <div
                style={{
                  marginLeft: "1vw",
                }}
              >
                <textarea
                  style={{
                    padding: "4px",
                    borderRadius: "10px",
                    height: "20vh",
                    width: "20vw",
                  }}
                  required
                  value={enquiryMsg}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Write Your Reply Here ...."
                />

                <label htmlFor="upload">
                  <img
                    style={{
                      width: "20vw",
                      marginTop: "2vh",
                      cursor: "pointer",
                    }}
                    src={upload}
                  />
                </label>
                <div>
                  <span
                    style={{
                      fontSize: "10px",
                      marginTop: "20vh",
                      // marginLeft:"-5vmax",
                      fontWeight: "normal",
                    }}
                    ref={spanref}
                  ></span>
                </div>
                <input
                  ref={imageref}
                  style={{ display: "none" }}
                  type="file"
                  id="upload"
                  accept="image"
                  onChange={handleImageChange}
                />
                <button
                  type="submit"
                  style={{
                    height: "4vh",
                    width: "8vw",
                    color: "#FFFFFF",
                    border: "none",
                    borderRadius: "6px",
                    marginTop: "6vh",
                    marginLeft: "12vw",
                    background: "#1894B8",
                  }}
                >
                  Reply
                </button>
                {isSubmitting && (
                  <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                    Loading...
                  </span>
                )}
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  success
                  theme="dark"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnquiryDetail;
