import { useState } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { BsEyeSlash } from "react-icons/bs";
import axios from "axios";
import { baseUrl } from "../localdata";

const Signup = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `/admin/signup`,
        {
          fullName,
          number,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(data);
      navigate("/login");
    } catch (error) {
      alert(error?.response?.data?.msg);
      console.log(error.response.data.msg);
    }
  };

  return (
    <div style={{ height: "100vh", width: "100vw", display: "flex" }}>
      <div
        style={{
          width: "40vw",
          height: "100vh",
          background: "#0F9EE8",
          boxShadow: "13px 0px 92px rgba(0, 0, 0, 0.18)",
        }}
      >
        <img className="logo" /*src={logo}*/ />
        <div style={{ width: "16vw", marginTop: "32vh", marginLeft: "6vw" }}>
          <h1 style={{ color: "white", fontSize: "60px", marginBottom: "1vh" }}>
            Sign up
          </h1>
          <hr
            style={{
              width: "6.5vw",
              height: "0.5vh",
              background: "#FFFFFF",
              marginLeft: "1vw",
            }}
          />
        </div>
      </div>
      <div
        style={{
          height: "7vh",
          marginLeft: "38vw",
          marginTop: "46.5vh",
          position: "absolute",
          width: "4vw",
          borderRadius: "30px",
          background: "#0F9EE8",
        }}
      >
        <AiOutlineRight
          style={{
            color: "#FFFFFF",
            marginTop: "1.8vh",
            marginLeft: "1.8vw",
            fontSize: "25px",
          }}
        />
      </div>
      <form onSubmit={submitForm}>
        <div>
          <div style={{ marginTop: "25vh", width: "32vw", marginLeft: "15vw" }}>
            <div>
              <h3
                style={{
                  color: "#0F9EE8",
                  fontWeight: "500",
                  marginBottom: "1vh",
                }}
              >
                Username
              </h3>
              <input
                type="text"
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="    Enter your name here"
                style={{
                  paddingBottom: "0.8vh",
                  width: "30vw",
                  height: "4vh",
                  outline: "none",
                  borderWidth: "0 0 0",
                  boxShadow: " 0px 4px 25px rgba(0, 0, 0, 0.09)",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div style={{ marginTop: "4vh" }}>
              <h3
                style={{
                  color: "#0F9EE8",
                  fontWeight: "500",
                  marginBottom: "1vh",
                }}
              >
                Phone Number
              </h3>
              <input
                type="text"
                required
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="    Enter your phone number"
                style={{
                  paddingBottom: "0.8vh",
                  width: "30vw",
                  height: "4vh",
                  borderWidth: "0 0 0",
                  boxShadow: " 0px 4px 25px rgba(0, 0, 0, 0.09)",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div style={{ position: "relative", marginTop: "4vh" }}>
              <h3
                style={{
                  color: "#0F9EE8",
                  fontWeight: "500",
                  marginBottom: "1vh",
                }}
              >
                Password
              </h3>
              <input
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="    Enter password"
                style={{
                  paddingBottom: "0.8vh",
                  width: "30vw",
                  height: "4vh",
                  outline: "none",
                  borderWidth: "0 0 0",
                  boxShadow: " 0px 4px 25px rgba(0, 0, 0, 0.09)",
                  borderRadius: "5px",
                }}
              />
              <BsEyeSlash
                style={{
                  position: "absolute",
                  marginTop: "1.5vh",
                  marginLeft: "-2vw",
                }}
              />
            </div>
          </div>
          <div>
            <button
              /*onClick={handleSubmit}*/ style={{
                height: "5vh",
                border: "0",
                color: "#FFFFFF",
                marginTop: "10vh",
                marginLeft: "27vw",
                width: "6vw",
                background: "#0F9EE8",
                borderRadius: "7px",
              }}
              type="submit"
            >
              Sign up
            </button>
          </div>
          <div style={{ marginLeft: "22vw" }}>
            <h4 style={{ color: "#0F9EE8", fontWeight: "600" }}>
              Already have an account?{" "}
              <Link to="/" style={{ color: "#0F9EE8" }}>
                Login
              </Link>
            </h4>
          </div>
        </div>
      </form>
    </div>
  );
};
export default Signup;
