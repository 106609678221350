import { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import useSWR from "swr";
import axios from "axios";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sortByDate, sortByTime } from "../helper";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";

const Orders = ({ searchQuery, onSearch }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  function handleCalenderView() {
    setShowCalendar(true);
  }

  function hideCalendar() {
    setShowCalendar(false);
  }
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  // console.warn(state, "line 28");

  const getSelectedDate = state.map((i) => {
    const startDate = i.startDate.toISOString().split("T")[0];
    const endDate = i.endDate?.toISOString().split("T")[0];
    return {
      startDate,
      endDate,
    };
  });

  let dateArray = [];
  let currentDate = moment(getSelectedDate[0].startDate);
  let stopDate = moment(getSelectedDate[0].endDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
    currentDate = moment(currentDate).add(1, "days");
  }
  console.log(dateArray);

  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });

  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    `/admin/getAllOrders/${adminId}/?offset=${10}`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );
  const open2 = true;
  console.log(data, "order list data");
  const [datas, setDatas] = useState([]);
  useEffect(() => {
    setDatas(data);
  }, [data]);

  const filtered = datas?.filter((order) => {
    return order?.number?.trim().toLowerCase().includes(searchQuery);
  });

  useEffect(() => {
    if (searchQuery?.length < 2 || searchQuery == "") {
      setDatas(data);
    } else {
      setDatas(filtered);
    }
  }, [searchQuery]);

  const [isOpen, setisOpen] = useState(false);
  const [accepted, setaccepted] = useState(false);
  const [rejected, setrejected] = useState(false);
  const [edit, setedit] = useState(false);
  const [index, setIndex] = useState(null);
  function handleMenuIconClick(id) {
    if (index == id) {
      setIndex(null);
    } else {
      setIndex(id);
    }
    // setIsOpen(!isOpen);
  }

  //  handle filter by dates->
  function filterBydates() {
    const newData = [...datas];
    const filtered = newData.filter((d) => {
      return dateArray.includes(d.date);
    });
    setDatas(filtered);
  }

  //  clear filter->

  function clearFilterDate() {
    setDatas(data);
  }

  const handleSort = () => {
    const sortedData = sortByDate([...datas]);

    setDatas(sortedData);
  };

  const handleSort2 = () => {
    const sortedData = sortByTime([...datas]);

    setDatas(sortedData);
  };

  // update order status  as aprooved ->
  const handleAccept = async (orderId) => {
    try {
      const { data } = axios.post(
        `/admin/updateOrderStatus/${orderId}/${adminId}`,

        {
          orderStatus: "approved",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast("Order Accepted");
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReject = async (orderId) => {
    try {
      const { data } = axios.post(
        `/admin/updateOrderStatus/${orderId}/${adminId}`,

        {
          orderStatus: "declined",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast("Order Rejected");
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="base">
        <div>
          <Sidebar />
        </div>
        <div>
          <Navbar onSearch={onSearch} />
          {!data ? (
            <Backdrop
              open={open2}
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <div
              style={{ height: "100vh", width: "80vw", background: "#EFEFEF" }}
            >
              <div
                className="container"
                style={{
                  height: "87.5vh",
                  position: "relative",
                  width: "78vw",
                  marginLeft: "1vw",
                  overflow: "auto",
                }}
              >
                {showCalendar && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginRight: "4vw",
                      marginTop: "4vh",
                    }}
                  >
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => setState([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={state}
                    />
                  </div>
                )}
                {showCalendar && (
                  <>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <button
                        onClick={filterBydates}
                        /*onClick={handleSubmit}*/ style={{
                          height: "5vh",
                          border: "0",
                          color: "#FFFFFF",
                          marginTop: "5vh",
                          marginLeft: "50vw",
                          width: "6vw",
                          background: "#0F9EE8",
                          borderRadius: "7px",
                          cursor: "pointer",
                        }}
                        type="button"
                      >
                        Apply
                      </button>
                      <button
                        onClick={clearFilterDate}
                        /*onClick={handleSubmit}*/ style={{
                          height: "5vh",
                          border: "0",
                          color: "#FFFFFF",
                          marginTop: "5vh",

                          width: "6vw",
                          background: "#0F9EE8",
                          borderRadius: "7px",
                          cursor: "pointer",
                        }}
                        type="button"
                      >
                        Clear
                      </button>
                      <button
                        onClick={hideCalendar}
                        /*onClick={handleSubmit}*/ style={{
                          height: "5vh",
                          border: "0",
                          color: "#FFFFFF",
                          marginTop: "5vh",

                          width: "6vw",
                          background: "#0F9EE8",
                          borderRadius: "7px",
                          cursor: "pointer",
                        }}
                        type="button"
                      >
                        Hide Calendar
                      </button>
                    </div>
                  </>
                )}
                {!showCalendar && (
                  <button
                    onClick={handleCalenderView}
                    /*onClick={handleSubmit}*/ style={{
                      height: "5vh",
                      border: "0",
                      color: "#FFFFFF",
                      marginTop: "5vh",
                      marginLeft: "65vw",
                      width: "6vw",
                      background: "#0F9EE8",
                      borderRadius: "7px",
                      cursor: "pointer",
                    }}
                    type="button"
                  >
                    Show Calendar
                  </button>
                )}
                {/* <hr
                  style={{
                    width: "9vw",
                    marginLeft: "65vw",
                    marginTop: "-1vh",
                    border: "1px solid #1894B8",
                  }}
                /> */}

                {isOpen && (
                  <div
                    style={{
                      background: "#FFFFFF",
                      margin: "0vh 0vw 0vh 64vw",
                      position: "absolute",
                      width: "11vw",
                      borderRadius: "10px",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                      zIndex: "1",
                      border: "none",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h5
                        onClick={handleSort}
                        style={{
                          margin: " 1.5vh 1vw 1.5vh 1vw",
                          cursor: "pointer",
                        }}
                      >
                        Date
                      </h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderTop: "1px solid #777777",
                      }}
                    >
                      <h5
                        onClick={handleSort2}
                        style={{
                          margin: " 1.5vh 1vw 1.5vh 1vw",
                          cursor: "pointer",
                        }}
                      >
                        Time
                      </h5>
                    </div>
                    {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTop: "1px solid #777777",
                  }}
                >
                 
                </div> */}
                    {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTop: "1px solid #777777",
                  }}
                >
                  
                </div> */}
                  </div>
                )}

                <h2>Orders</h2>
                <hr style={{ width: "77vw" }} />

                <table
                  className="tab"
                  style={{ width: "100%", position: "relative" }}
                >
                  <tr className="tabtr">
                    <th
                      style={{
                        textAlign: "start",
                        color: "#1894B8",
                        fontSize: "14px",
                      }}
                    >
                      Sr No.{" "}
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        color: "#1894B8",
                        fontSize: "14px",
                      }}
                    >
                      Date{" "}
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        color: "#1894B8",
                        fontSize: "14px",
                      }}
                    >
                      Time{" "}
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        color: "#1894B8",
                        fontSize: "14px",
                      }}
                    >
                      Full Name{" "}
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        color: "#1894B8",
                        fontSize: "14px",
                      }}
                    >
                      Order Id{" "}
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        color: "#1894B8",
                        fontSize: "14px",
                      }}
                    >
                      Mobile Number{" "}
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        color: "#1894B8",
                        fontSize: "14px",
                      }}
                    >
                      Plant{" "}
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        color: "#1894B8",
                        fontSize: "14px",
                      }}
                    >
                      State{" "}
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        color: "#1894B8",
                        fontSize: "14px",
                      }}
                    >
                      Product Name{" "}
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        color: "#1894B8",
                        fontSize: "14px",
                      }}
                    >
                      Net Price{" "}
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        color: "#1894B8",
                        fontSize: "14px",
                      }}
                    >
                      Order Quantity{" "}
                    </th>
                    <th
                      style={{
                        textAlign: "start",
                        color: "#1894B8",
                        fontSize: "14px",
                      }}
                    >
                      Order Amount{" "}
                    </th>
                  </tr>

                  {datas &&
                    datas.map((item, i) => (
                      <tr key={item._id} style={{ paddingTop: "2vh" }}>
                        <td
                          style={{
                            textAlign: "start",
                            fontSize: "10.5px",
                          }}
                        >
                          {i + 1}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            fontSize: "10.5px",
                          }}
                        >
                          {moment(item?.date).format("DD-MM-YYYY")}
                          {/* {item.date} */}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            fontSize: "10.5px",
                          }}
                        >
                          {item.time}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            fontSize: "10.5px",
                          }}
                        >
                          {item.fullName}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            fontSize: "10.5px",
                          }}
                        >
                          {item.orderNumber}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            fontSize: "10.5px",
                          }}
                        >
                          {item.number}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            fontSize: "10.5px",
                          }}
                        >
                          {item.state}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            fontSize: "10.5px",
                          }}
                        >
                          {item.state}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            fontSize: "10.5px",
                          }}
                        >
                          {item.product?.stockName}
                        </td>
                        <td
                          contenteditable={edit ? "true" : "false"}
                          style={{
                            textAlign: "start",
                            fontSize: "10.5px",
                          }}
                        >
                          {item.netPrice}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: "10.5px",
                          }}
                        >
                          {item.orderQuantity}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            fontSize: "10.5px",
                          }}
                        >
                          {item.orderAmount}
                        </td>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <>
                            {item.status === "pending" ? (
                              <>
                                <button
                                  onClick={() => handleAccept(item._id)}
                                  style={{
                                    width: "5vw",
                                    height: "3vh",
                                    color: "#1894B8",
                                    background: "#FFFFFF",
                                    border: "1px solid #1894B8",
                                    borderRadius: "6px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Accept
                                </button>
                                <ToastContainer
                                  position="top-center"
                                  autoClose={5000}
                                  hideProgressBar={false}
                                  newestOnTop={false}
                                  closeOnClick
                                  rtl={false}
                                  pauseOnFocusLoss
                                  draggable
                                  pauseOnHover
                                  success
                                  theme="dark"
                                />
                                <button
                                  onClick={() => handleReject(item._id)}
                                  style={{
                                    width: "5vw",
                                    height: "3vh",
                                    color: "#1894B8",
                                    background: "#FFFFFF",
                                    border: "1px solid #1894B8",
                                    borderRadius: "6px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Reject
                                </button>
                                <ToastContainer
                                  position="top-center"
                                  autoClose={5000}
                                  hideProgressBar={false}
                                  newestOnTop={false}
                                  closeOnClick
                                  rtl={false}
                                  pauseOnFocusLoss
                                  draggable
                                  pauseOnHover
                                  success
                                  theme="dark"
                                />
                              </>
                            ) : item.status === "approved" ? (
                              <button
                                style={{
                                  width: "5vw",
                                  height: "3vh",
                                  color: "#1894B8",
                                  background: "#FFFFFF",
                                  border: "1px solid #1894B8",
                                  borderRadius: "6px",
                                  cursor: "pointer",
                                }}
                              >
                                Accepted
                              </button>
                            ) : item.status === "declined" ? (
                              <button
                                style={{
                                  width: "5vw",
                                  height: "3vh",
                                  color: "#1894B8",
                                  background: "#FFFFFF",
                                  border: "1px solid #1894B8",
                                  borderRadius: "6px",
                                  cursor: "pointer",
                                }}
                              >
                                Rejected
                              </button>
                            ) : null}
                          </>
                        </div>
                        {/* <div
                      style={{
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                        background: "#FFFFFF",
                        borderRadius: "10px",
                        display: index === item._id ? "block" : "none",
                        position: "absolute",
                        right: "2vw",
                      }}
                    >
                      <>
                        <div
                          onClick={() => setedit(true)}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            padding: "4px",
                          }}
                        >
                          <MdModeEditOutline style={{ paddingRight: "2vw" }} />
                          <h5 style={{ margin: "0" }}>Edit</h5>
                        </div>
                      </>
                    </div> */}
                        {/* <td
                      style={{
                        "font-size": "15px",
                        textAlign: "center",
                      }}
                    >
                      <a
                        onClick={() => handleMenuIconClick(item._id)}
                        style={{ cursor: "pointer" }}
                      >
                        <BiDotsVerticalRounded />
                      </a>
                    </td> */}
                      </tr>
                    ))}
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Orders;

{
  /* <button
onClick={() => handleAccept(item._id)}
style={{
  width: "5vw",
  height: "3vh",
  color: "#1894B8",
  background: "#FFFFFF",
  border: "1px solid #1894B8",
  borderRadius: "6px",
  cursor: "pointer",
}}
>
Accepted
</button>  <button
                            onClick={() => handleAccept(item._id)}
                            style={{
                              width: "5vw",
                              height: "3vh",
                              color: "#1894B8",
                              background: "#FFFFFF",
                              border: "1px solid #1894B8",
                              borderRadius: "6px",
                              cursor: "pointer",
                            }}
                          >
                            Accepted
                          </button> */
}
