import React, { useState, useRef, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import upload from "./large.png";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { admin, baseUrl, states } from "../localdata";

const UploadBill = () => {
  const [adminId, setId] = useState("");
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  const [userName, setUserName] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [pdf, setPdf] = useState("");

  const imageRef = useRef();
  const spanref = useRef();

  useEffect(() => {
    const element = imageRef.current;

    element.addEventListener("change", () => {
      const inputImage = element.files[0];
      spanref.current.innerHTML = inputImage.name;
    });
  });

  const submitForm = async (e) => {
    const myForm = new FormData();
    myForm.append("userName", userName);
    myForm.append("userNumber", userNumber);

    myForm.append("pdf", pdf);

    try {
      const { data } = await axios.post(
        `/admin/upload/bills`,

        myForm,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast("Bill Added");
      console.log(data);
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  const handleFileChange = (e) => {
    setPdf(e.target.files[0]);
  };
  return (
    <>
      <div className="base">
        <div>
          <Sidebar />
        </div>
        <div>
          <Navbar />
          <form onSubmit={handleSubmit(submitForm)}>
            <div
              style={{
                height: "100vh",
                overflow: "scroll",
                width: "74vw",
                paddingLeft: "6vw",
                background: "#EFEFEF",
              }}
            >
              <div style={{ display: "flex", paddingTop: "6vh" }}>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>User Name</h3>
                  <input
                    type="text"
                    required
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="    User Name"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
                <div>
                  <h3 style={{ marginLeft: "2vw" }}>User Number</h3>

                  <input
                    type="text"
                    required
                    value={userNumber}
                    onChange={(e) => setUserNumber(e.target.value)}
                    placeholder="   User Number"
                    style={{ height: "4vh", width: "30vw", marginLeft: "2vw" }}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}></div>

              <div
                style={{ display: "flex", flexDirection: "column", gap: "1vw" }}
              >
                <label
                  htmlFor=""
                  style={{ fontWeight: "bold", marginTop: "1vh" }}
                >
                  Choose a file
                </label>
                <label htmlFor="excel">
                  <img
                    src={upload}
                    alt="upload"
                    style={{
                      width: "6vw",
                      cursor: "pointer",
                      marginTop: "2vh",
                    }}
                  />
                </label>
              </div>

              <input
                ref={imageRef}
                style={{ display: "none" }}
                id="excel"
                type="file"
                onChange={handleFileChange}
              />
              <span
                style={{ fontSize: "12px", marginTop: "5vh" }}
                ref={spanref}
              ></span>
              {/* <div style={{ display: "grid" }}>
                {formData.map((data, index) =>
                  generateInputFields(data, index)
                )}
              </div> */}
              <button
                disabled={isSubmitting}
                style={{
                  color: "#FFFFFF",
                  background: "#1894B8",
                  height: "4vh",
                  width: "10vw",
                  marginTop: "4vh",
                  marginLeft: "53vw",
                  border: "none",
                  borderRadius: "9px",
                }}
                type="submit"
              >
                Upload
              </button>
              {isSubmitting && (
                <span style={{ fontWeight: "bold", marginLeft: "1vmax" }}>
                  Loading...
                </span>
              )}
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                success
                theme="dark"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UploadBill;
