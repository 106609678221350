import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import dash1 from "./dash1.png";
import two from "./two.png";
import three from "./three.png";
import four from "./four.png";
import five from "./five.png";
import six from "./six.png";
import seven from "./seven.png";
import eight from "./eight.png";
import nine from "./nine.png";
import dash from "./dash.png";
import two1 from "./two1.png";
import three1 from "./three1.png";
import four1 from "./four1.png";
import five1 from "./five1.png";
import six1 from "./six1.png";
import seven1 from "./seven1.png";
import eight1 from "./eight1.png";
import nine1 from "./nine1.png";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setopen] = useState(false);
  const [opendepot, setopendepot] = useState(false);
  const [state, setstate] = useState(false);
  const toUsers = () => {
    navigate("/user");
  };
  const toHome = () => {
    navigate("/home");
  };
  const openMrates = () => {
    setopen(true);
    navigate("/plantrates");
  };
  const toBills = () => {
    navigate("/bills");
  };
  const toStocks = () => {
    navigate("/stocks");
  };
  const toPayment = () => {
    navigate("/payments");
  };
  const toOrders = () => {
    navigate("/orders");
  };
  const toGraph = () => {
    navigate("/graph");
  };
  const toAd = () => {
    navigate("/ad");
  };
  const openDepot = () => {
    navigate("/depotrates");
  };
  const openPlant = () => {
    navigate("/plantrates");
  };

const handleLogout = ()=>{
  sessionStorage.removeItem('admin');
  navigate("/login")
}

  return (
    <div
      style={{
        width: "20vw",
        height: "112vh",
        position: "sticky",
        border: "none",
        background: "#0F9EE8",
      }}
    >
      <div style={{ padding: "2vh 0 0 4vw" }}>
        <h2 style={{ color: "#FFFFFF", margin: "0" }}>ADMIN</h2>
      </div>

      <div style={{ paddingTop: "6vh", marginLeft: "4vw" }}>
        <div
          onClick={toHome}
          style={{
            cursor: "pointer",
            height: "6vh",
            width: "16vw",
            background: location.pathname === "/home" ? "#EFEFEF" : "",
            display: "flex",
            alignItems: "center",
            borderRadius: "40px 0px 0px 40px",
          }}
        >
          <img
            style={{ marginLeft: "1vw" }}
            src={location.pathname === "/home" ? dash1 : dash}
          />
          <h4
            style={{
              color: location.pathname === "/home" ? "#1894B8" : "#FFFFFF",
              marginLeft: "2vw",
            }}
          >
            Dashboard
          </h4>
        </div>
        <div
          onClick={toUsers}
          style={{
            cursor: "pointer",
            background:
              location.pathname === "/user" || location.pathname === "/adduser"
                ? "#EFEFEF"
                : "",
            height: "6vh",
            width: "16vw",
            display: "flex",
            alignItems: "center",
            marginTop: "3vh",
            borderRadius: "40px 0px 0px 40px",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <img
            style={{ marginLeft: "1vw" }}
            src={
              location.pathname === "/user" || location.pathname === "/adduser"
                ? two1
                : two
            }
          />
          <h4
            style={{
              color:
                location.pathname === "/user" ||
                location.pathname === "/adduser"
                  ? "#1894B8"
                  : "#FFFFFF",
              marginLeft: "2vw",
            }}
          >
            User Management
          </h4>
        </div>
        <div
          onClick={openMrates}
          style={{
            cursor: "pointer",
            background:
              location.pathname === "/plantrates" ||
              location.pathname === "/depotrates"
                ? "#EFEFEF"
                : "",
            height: "6vh",
            width: "16vw",
            display: "flex",
            alignItems: "center",
            marginTop: "3vh",
            borderRadius: "40px 0px 0px 40px",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <img
            style={{ marginLeft: "1vw" }}
            src={
              location.pathname === "/plantrates" ||
              location.pathname === "/depotrates"
                ? three1
                : three
            }
          />
          <h4
            style={{
              color:
                location.pathname === "/plantrates" ||
                location.pathname === "/depotrates"
                  ? "#1894B8"
                  : "#FFFFFF",
              marginLeft: "2vw",
            }}
          >
            Manage Rates
          </h4>
        </div>
        <div style={{}}>
          <h4
            onClick={openPlant}
            style={{
              color:
                location.pathname === "/plantrates" ? "#EFEFEF" : "#D4D4D4",
              cursor: "pointer",
              marginLeft: "4vw",
              marginTop: "2vh",
              marginBottom: "-1vh",
            }}
          >
            <b>Factory Rate</b>
          </h4>
          <h4
            onClick={openDepot}
            style={{
              cursor: "pointer",
              color:
                location.pathname === "/depotrates" ? "#EFEFEF" : "#D4D4D4",
              marginLeft: "4vw",
            }}
          >
            Retail Rate
          </h4>
        </div>
        <div
          onClick={toOrders}
          style={{
            cursor: "pointer",
            height: "6vh",
            background: location.pathname === "/orders" ? "#EFEFEF" : "",
            width: "16vw",
            display: "flex",
            alignItems: "center",
            marginTop: "3vh",
            borderRadius: "40px 0px 0px 40px",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <img
            style={{ marginLeft: "1vw" }}
            src={location.pathname === "/orders" ? four1 : four}
          />
          <h4
            style={{
              color: location.pathname === "/orders" ? "#1894B8" : "#FFFFFF",
              marginLeft: "2vw",
            }}
          >
            Orders
          </h4>
        </div>
        <div
          onClick={toPayment}
          style={{
            cursor: "pointer",
            background: location.pathname === "/payments" ? "#EFEFEF" : "",
            height: "6vh",
            width: "16vw",
            display: "flex",
            alignItems: "center",
            marginTop: "3vh",
            borderRadius: "40px 0px 0px 40px",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <img
            style={{ marginLeft: "1vw" }}
            src={location.pathname === "/payments" ? five1 : five}
          />{" "}
          <h4
            style={{
              color: location.pathname === "/payments" ? "#1894B8" : "#FFFFFF",
              marginLeft: "2vw",
            }}
          >
            Payment
          </h4>
        </div>
        <div
          onClick={toBills}
          style={{
            cursor: "pointer",
            background: location.pathname === "/bills" ? "#EFEFEF" : "",
            height: "6vh",
            width: "16vw",
            display: "flex",
            alignItems: "center",
            marginTop: "3vh",
            borderRadius: "40px 0px 0px 40px",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <img
            style={{ marginLeft: "1vw" }}
            src={location.pathname === "/bills" ? six1 : six}
          />{" "}
          <h4
            style={{
              color: location.pathname === "/bills" ? "#1894B8" : "#FFFFFF",
              marginLeft: "2vw",
            }}
          >
            Bills
          </h4>
        </div>
        <div
          onClick={toStocks}
          style={{
            cursor: "pointer",
            background: location.pathname === "/stocks" ? "#EFEFEF" : "",
            height: "6vh",
            width: "16vw",
            display: "flex",
            alignItems: "center",
            marginTop: "3vh",
            borderRadius: "40px 0px 0px 40px",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <img
            style={{ marginLeft: "1vw" }}
            src={location.pathname === "/stocks" ? seven1 : seven}
          />
          <h4
            style={{
              color: location.pathname === "/stocks" ? "#1894B8" : "#FFFFFF",
              marginLeft: "2vw",
            }}
          >
            Stock
          </h4>
        </div>
        <div
          onClick={toGraph}
          style={{
            height: "6vh",
            cursor: "pointer",
            background: location.pathname === "/graph" ? "#EFEFEF" : "",
            width: "16vw",
            display: "flex",
            alignItems: "center",
            marginTop: "3vh",
            borderRadius: "40px 0px 0px 40px",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <img
            style={{ marginLeft: "1vw" }}
            src={location.pathname === "/graph" ? eight1 : eight}
          />
          <h4
            style={{
              color: location.pathname === "/graph" ? "#1894B8" : "#FFFFFF",
              marginLeft: "2vw",
            }}
          >
            Graph
          </h4>
        </div>
        <div
          onClick={toAd}
          style={{
            height: "6vh",
            cursor: "pointer",
            background: location.pathname === "/ad" ? "#EFEFEF" : "",
            width: "16vw",
            display: "flex",
            alignItems: "center",
            marginTop: "3vh",
            borderRadius: "40px 0px 0px 40px",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <img
            style={{ marginLeft: "1vw" }}
            src={location.pathname === "/ad" ? nine1 : nine}
          />
          <h4
            style={{
              color: location.pathname === "/ad" ? "#1894B8" : "#FFFFFF",
              marginLeft: "2vw",
            }}
          >
            Advertisement
          </h4>
        </div>
        <div
          onClick={handleLogout}
          style={{
            height: "6vh",
            cursor: "pointer",
            
            width: "16vw",
            display: "flex",
            alignItems: "center",
            marginTop: "3vh",
            borderRadius: "40px 0px 0px 40px",
            display: "flex",
            alignSelf: "center",
          }}
        >
          <MdLogout onClick={handleLogout} style={{ marginLeft: "1vw",color:'white' }} />
          <h4
          onClick={handleLogout}
            style={{
              color:'white',
              marginLeft: "2vw",
            }}
          >
            Logout
          </h4>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
