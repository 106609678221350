import React from "react";
import useSWR from "swr";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
const WeeklyGraph = () => {
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(` /admin/getWeekData`, fetcher, {
    refreshInterval: 1000,
  });
  return (
    <>
      <h1>Weekly graph</h1>
      <LineChart
        width={1100}
        height={550}
        data={data?.graphData}
        margin={{ top: 60, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip
          formatter={(value, name) => [value, "Basic Rate"]}
          labelFormatter={(value) => `Week: ${value}`}
        />
        <Line
          type="monotone"
          dataKey="basic"
          stroke="#141414"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </>
  );
};

export default WeeklyGraph;
