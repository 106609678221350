import { useState, useEffect } from "react";
import useSWR from "swr";
import moment from "moment";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { admin, baseUrl } from "../localdata";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Board from "../Board/Board";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

const TotalOrder = () => {
  const [adminId, setId] = useState("");

  useEffect(() => {
    const admin = JSON.parse(sessionStorage.getItem("admin"));
    if (admin) {
      setId(admin._id);
    }
  });
  console.log(adminId, "admin id here");
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error, loading } = useSWR(
    ` /admin/getAllOrders/${adminId}`,
    fetcher,
    {
      refreshInterval: 1000,
    }
  );
  console.log(data, "total order list page");

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const open2 = true;
  const AlertDialog = () => {
    return (
      <div>
        <Dialog
          sx={{ width: 1700 }}
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Etiam auctor dolor bibendum id posuere enim scelerisque id.
              Pellentesque massa adipiscing id enim sagittis donec sit. Elit ac
              neque id diam. Mollis congue porta tincidunt tellus dictumst
              porttitor. Eu suspendisse pretium quis tincidunt tellus. Ut donec
              dolor euismod habitant commodo rhoncus.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                width: "18vw",
                height: "4vh",
                color: "#FFFFFF",
                background: "#1894B8",
                border: "none",
                marginLeft: "1vw",
                borderRadius: "6px",
              }}
              // onClick={handleClose}
            >
              Generate and Send
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  return (
    <div className="base">
      <div>
        <Sidebar />
      </div>
      <div>
        <Navbar />
        {!data ? (
          <Backdrop
            open={open2}
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <div
            style={{ height: "100vh", width: "80vw", background: "#EFEFEF" }}
          >
            <div style={{ display: "flex", marginLeft: "10vw" }}>
              <Board orders={data} />
            </div>
            <div
              className="container"
              style={{
                height: "70vh",
                width: "78vw",
                marginLeft: "1vw",
                overflow: "auto",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2>Orders</h2>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "4vw",
                  }}
                >
                  <AiOutlineArrowLeft style={{ cursor: "pointer" }} />{" "}
                  <AiOutlineArrowRight
                    style={{ cursor: "pointer", marginLeft: "1vw" }}
                  />
                </div>
              </div>

              <hr style={{ width: "77vw" }} />
              <table className="tab" style={{ width: "100%" }}>
                <tr className="tabtr">
                  <th style={{ textAlign: "start", color: "#1894B8" }}>Date</th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Full User Name
                  </th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Mobile Number
                  </th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    State
                  </th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Product Name
                  </th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Net Price
                  </th>
                  <th style={{ textAlign: "center", color: "#1894B8" }}>
                    Order Quantity
                  </th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Order Amount
                  </th>
                  <th style={{ textAlign: "start", color: "#1894B8" }}>
                    Status
                  </th>
                </tr>

                {data &&
                  data?.map((item, i) => (
                    <tr
                      // onClick={handleClickOpen}
                      key={i}
                      // style={{ cursor: "pointer", paddingTop: "2vh" }}
                    >
                      <td
                        style={{
                          textAlign: "start",
                        }}
                      >
                        {moment(item?.date).format("DD-MM-YYYY")}
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                        }}
                      >
                        {item.fullName}
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                        }}
                      >
                        {item.number}
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                        }}
                      >
                        {item.state}
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                        }}
                      >
                        {item.product?.stockName}
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                        }}
                      >
                        {item.netPrice}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {item.orderQuantity}
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                        }}
                      >
                        {item.orderAmount}
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          color: item.status === "approved" ? "green" : "red",
                        }}
                      >
                        {item.status}
                      </td>
                      {/* <td style={{
                        
                        "font-size": "15px",
                        textAlign:"center"
                      }}><a onClick={()=> handleMenuIconClick((item.id))} style={{cursor:"pointer"}}><BiDotsVerticalRounded/></a></td> */}
                      {/* <div style={{  boxShadow:"0px 4px 20px rgba(0, 0, 0, 0.15)",background: "#FFFFFF",borderRadius:"10px" ,display: index === item.id ? "block" : "none" , position: "absolute" ,right:"1.5vw"}}> */}
                      {/* <>
                      <div onClick={toEdit} style={{ cursor:"pointer",display:"flex" ,padding:"4px" ,borderBottom:"1px solid black"}}><MdModeEditOutline style={{paddingRight:"2vw"}}/><h5 style={{margin: "0" }}>Edit</h5></div>
                      <div style={{ cursor:"pointer",display:"flex",padding:"4px",borderBottom:"1px solid black" }}><MdDelete style={{paddingRight:"2vw"}}/><h5 style={{margin: "0" }}>Block</h5> </div>
                      </> */}
                      {/* </div> */}
                    </tr>
                  ))}
              </table>
              <AlertDialog />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default TotalOrder;
